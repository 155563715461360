import { envVariablesHelper } from '@general/config-manager';

export const config = {
  env: envVariablesHelper.getString('REACT_APP_NODE_ENV', window.env),
  apiUrl: envVariablesHelper.getString('REACT_APP_API_URL', window.env),
  corporateSiteUrl: envVariablesHelper.getString('REACT_APP_CORPORATE_SITE_URL', window.env),
  customerPortalUrl: envVariablesHelper.getString('REACT_APP_CUSTOMER_PORTAL_URL', window.env),
  hubspotTrackingCode: envVariablesHelper.getString('REACT_APP_HUBSPOT_TRACKING_CODE', window.env),
  inactivityTimeout: envVariablesHelper.getNumber('REACT_APP_INACTIVITY_TIMEOUT', window.env),
  onfido: {
    sdkReferrer: envVariablesHelper.getString('REACT_APP_ONFIDO_SDK_REFERRER', window.env)
  },
  sentry: {
    dsn: envVariablesHelper.getString('REACT_APP_SENTRY_DSN', window.env),
    enabled: envVariablesHelper.getString('REACT_APP_SENTRY_ENABLED', window.env)
  },
  recaptchaSiteKey: envVariablesHelper.getString('REACT_APP_RECAPTCHA_SITE_KEY', window.env),
  isVoiceCallEnabled: envVariablesHelper.getBoolean('REACT_APP_IS_VOICE_CALL_ENABLED', window.env),
  webAuth: {
    domain: envVariablesHelper.getString('REACT_APP_WEB_AUTH_DOMAIN', window.env),
    origin: envVariablesHelper.getString('REACT_APP_WEB_AUTH_ORIGIN', window.env)
  },
  gaTrackingId: envVariablesHelper.getString('REACT_APP_GA_TRACKING_ID', window.env),
  isScriveEnabled: envVariablesHelper.getBoolean('REACT_APP_IS_SCRIVE_ENABLED', window.env),
  scriveProviders: envVariablesHelper.getArray('REACT_APP_SCRIVE_PROVIDERS', ',', window.env),
  inchargeProductAccountId: envVariablesHelper.getString('REACT_APP_INCHARGE_PRODUCT_ACCOUNT_ID', window.env),
  is2dEidEnabled: envVariablesHelper.getBoolean('REACT_APP_IS_2D_EID_ENABLED', window.env),
  googleTagManagerId: envVariablesHelper.getString('REACT_APP_GOOGLE_TAG_MANAGER_ID', window.env),
  intercomAppId: envVariablesHelper.getString('REACT_APP_INTERCOM_APP_ID', window.env),
  intercomApiBase: envVariablesHelper.getString('REACT_APP_INTERCOM_API_BASE', window.env),
  localazy: {
    onboardingUiUrl: envVariablesHelper.getString('REACT_APP_LOCALES_SOURCE_URL_ONBOARDING_UI', window.env),
    onfidoUrl: envVariablesHelper.getString('REACT_APP_LOCALES_SOURCE_URL_ONFIDO', window.env),
  },
  seon: {
    host: envVariablesHelper.getString('REACT_APP_SEON_HOST', window.env),
    enabled: envVariablesHelper.getBoolean('REACT_APP_SEON_ENABLED', window.env),
  },
  mobileSdkAllowedKeys: envVariablesHelper.getString(
    'REACT_APP_ALLOWED_KEYS',
    window.env,
  )
    ? JSON.parse(
      envVariablesHelper.getString('REACT_APP_ALLOWED_KEYS', window.env),
    )
    : undefined,
};
