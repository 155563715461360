import api from '@general/intergiro-client';

import config from 'src/config';
import { deleteSession, getToken, isIndividualLogin, isTokenValid } from 'src/utils/storage';

import { redirectToLogin } from 'src/2d/utils/redirect-to-login';
import { retryIndividualSession } from 'src/3d/utils/retry-individual-session';

export class ApiError extends Error {
  constructor(data, status) {
    super(data);
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.constructor = ApiError;
    this.__proto__ = ApiError.prototype;
    this.data = data;
    this.status = status;
  }
}

export const generalErrorHandler = (status, data) => {
  if (status === 400 && data && data.errors) {
    const errors = data.errors.reduce((acc, error) => {
      if (!acc[error.field]) {
        acc[error.field] = error.message;
      }

      return acc;
    }, {});

    throw new ApiError(errors, status);
  }

  if (data.errors.length === 1) {
    const error = data.errors[0];
    if (error.code) {
      throw new ApiError({ code: error.code, message: error.message }, status);
    }
    const { payload } = error;
    if (payload) {
      throw new ApiError(payload, status);
    }
  }

  throw new ApiError({}, status);
};

export const signOut = async () => {
  const token = getToken();
  if (token) {
    if (isTokenValid(token)) {
      try {
        await api.auth.signout();
      } catch {
        //
      }
    }

    if (isIndividualLogin(token)) {
      retryIndividualSession();
    } else {
      deleteSession();
      redirectToLogin();
    }
  }
};

export const apiInit = ({ onFail, refreshToken, storage }) => {
  api.init(
    {
      apiServerUrl: config.apiUrl,
      onFail,
    },
    refreshToken
  );
  if (storage) {
    api.setLocalStorage(storage);
  }
};
