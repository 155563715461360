import { INDIVIDUAL_ROUTE, ROUTE_PREFIX } from 'src/constants';
import { deleteIndividualSession } from 'src/utils/storage';

export const retryIndividualSession = () => {
  const individualOnboardingLink = sessionStorage.getItem('individualOnboardingLink')
    || `${document.location.origin}${ROUTE_PREFIX}/${INDIVIDUAL_ROUTE}`;

  deleteIndividualSession();
  localStorage.setItem('showSessionExpirationMessage', true);
  window.location.href = individualOnboardingLink;
};
