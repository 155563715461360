import api from '@general/intergiro-client';

import { LOGIN_TYPES } from 'src/constants/login-types';

import { redirectToLogin } from 'src/2d/utils/redirect-to-login';

let refreshInProgress = false;

export const getToken = () => localStorage.getItem('token');

export const getRefreshToken = () => localStorage.getItem('refreshToken');

export const getSessionToken = () => sessionStorage.getItem('token');
export const getSessionRefreshToken = () => sessionStorage.getItem('refreshToken');

function wait() {
  return new Promise(resolve => {
    setInterval(() => {
      if (refreshInProgress) {
        return wait();
      }

      return resolve();
    }, 1000);
  });
}

export async function refreshTokenFn() {
  if (refreshInProgress) {
    await wait();

    return getToken();
  }

  try {
    refreshInProgress = true;

    const token = await api.getRefreshToken();
    const { accessToken, refreshToken } = await api.auth.refreshToken({
      refreshToken: token,
    });

    await api.setToken(accessToken);
    await api.setRefreshToken(refreshToken);

    return accessToken;
  } catch (error) {
    //
  } finally {
    refreshInProgress = false;
  }

  return null;
}

export async function refreshSessionTokenFn() {
  if (refreshInProgress) {
    await wait();

    return getSessionToken();
  }

  try {
    refreshInProgress = true;

    const token = getSessionRefreshToken();
    const { accessToken, refreshToken } = await api.auth.refreshToken({
      refreshToken: token,
    });

    sessionStorage.setItem('token', accessToken);
    sessionStorage.setItem('refreshToken', refreshToken);

    return accessToken;
  } catch (error) {
    //
  } finally {
    refreshInProgress = false;
  }

  return null;
}

export const setCurrentStage = currentStage => localStorage.setItem('currentStage', currentStage);

export const setSession = ({ accessToken, refreshToken, personId, corporationId }) => {
  api.setToken(accessToken);
  api.setRefreshToken(refreshToken);
  localStorage.setItem('personId', personId);
  localStorage.setItem('corporationId', corporationId);
};

export const setIndividualSession = ({ accessToken, refreshToken, individualId, corporationId }) => {
  sessionStorage.setItem('token', accessToken);
  sessionStorage.setItem('refreshToken', refreshToken);
  sessionStorage.setItem('individualId', individualId);
  sessionStorage.setItem('corporationId', corporationId);
};

export const getPersonId = () => {
  let personId = localStorage.getItem('personId');
  const token = getToken();
  if (!personId && token) {
    const { claims } = getTokenPayload(token);

    personId = claims.personId;
    if (personId) {
      localStorage.setItem('personId', personId);
    }
  }

  return personId;
};

export const getCorporationId = () => {
  let corporationId = localStorage.getItem('corporationId');
  const token = getToken();
  if (!corporationId && token) {
    const { claims } = getTokenPayload(token);
    corporationId = claims.corporationId;
    if (corporationId) {
      localStorage.setItem('corporationId', corporationId);
    }
  }

  return corporationId;
};

export const deleteSession = () => {
  api.removeToken();
  api.removeRefreshToken();
  localStorage.removeItem('personId');
  localStorage.removeItem('corporationId');
  localStorage.removeItem('token');
  localStorage.removeItem('isInvited');
  sessionStorage.removeItem('marketingTracked');
};

export const deleteIndividualSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('refreshToken');
  sessionStorage.removeItem('applicationId');
  sessionStorage.removeItem('individualId');
  sessionStorage.removeItem('corporationId');
  sessionStorage.removeItem('currentStage');
  sessionStorage.removeItem('individualOnboardingLink');
  sessionStorage.removeItem('returnUrl');
};

export const getIndividualCorporationId = () => {
  let corporationId = sessionStorage.getItem('corporationId');
  const token = getToken();
  if (!corporationId && token) {
    const { claims } = getTokenPayload(token);
    corporationId = claims.corporationId;
    if (corporationId) {
      sessionStorage.setItem('corporationId', corporationId);
    }
  }

  return corporationId;
};

export const getApplicationId = () => sessionStorage.getItem('applicationId');

export const getIndividualId = () => sessionStorage.getItem('individualId');

export const getTokenPayload = token => {
  return JSON.parse(atob(token.split('.')[1]));
};

export const getEphemeralTokenPayload = token => {
  return JSON.parse(atob(token)).payload;
};

export const parseToken = () => {
  const token = getToken();

  try {
    return token ? getTokenPayload(token) : token;
  } catch (err) {
    deleteSession();
    redirectToLogin();
  }
};

export const isSessionValid = () => {
  if (getToken() && getPersonId() && getCorporationId()) {
    return true;
  }

  return false;
};

export const isIndividualSessionValid = () => {
  if (getSessionToken() && getIndividualId() && getIndividualCorporationId()) {
    return true;
  }

  return false;
};

export const isTokenValid = token => {
  try {
    const { exp } = getTokenPayload(token);
    const currentDate = new Date().getTime();

    return exp * 1000 > currentDate;
  } catch {
    return false;
  }
};

export const isIndividualLogin = token => {
  token = token || getSessionToken();
  let isIndividual;
  try {
    isIndividual = getTokenPayload(token).login.type === LOGIN_TYPES.individual;
  } catch {
    isIndividual = false;
  }

  return isIndividual;
};

export const isInvited = () => localStorage.getItem('isInvited') === 'true';
